.App {}

.multi-column {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

@media (max-width: 639px) {
  .multi-column {
    flex-direction: column;
    justify-content: center;
  }

  /*
  #what-is-club-lulu-image-container {
    display: flex;
    justify-content: center;
  }
  #roadmap-image-container {
    margin: auto;
  }
  #what-is-club-lulu-image-container {
    margin: auto;
  }
    #meet-the-team-container {
    margin: auto;
  }
  */
}

/* This is old CSS that I will get rid of once we go live. Keeping it in case I need something from it down the line */

/*
#star-image-container {
  display: flex;
  justify-content: center;
}

#star-image {
  width: 60%;
}

#cow-dog-lulus-container {
  display: flex;
  justify-content: center;
  margin: 20px;
}

#cow-dog-lulus {
  width: 80%;
  padding-top: 10px;
}
#meet-the-team-container {
  margin: auto;
}
#roadmap-image-container {
  margin: auto;
}
#roadmap-container {
  background-color: #E6DBEC;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 20px;
  padding-left: 20px;
}
#what-is-club-lulu-text {
  padding-top: 30px;
}
#what-is-club-lulu {
  margin: 20px;
}
.column {
  flex: 1 1 0px;
}
.text-content {
  width: 80%;
  margin: auto;
  font-size: 24px;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
#what-is-club-lulu-image-container {
  max-width: 400px;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.lulu-gif {
  max-width: 100%;
}
.App-link {
  color: #61dafb;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.club-card-image {
  width: 33%;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

*/